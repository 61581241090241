import { defineAsyncComponent } from "vue";
import store from "../store/index";

const definitions = {
  project_settings: {
    api: {
      list: "/multioffer/project/list",
      update: "/multioffer/project/update",
      delete: "/multioffer/project/delete",
      create: "/multioffer/project/create",
    },
    columns: [
      {
        name: "token",
        label: "Token",
        field: (row) => row.name,
        format: (val) => `${val}`,
        sortable: true,
        visible: true,
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      {
        name: "description",
        label: "Description",
        field: (row) => row.name,
        sortable: true,
        visible: true,
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      {
        name: "content_id",
        label: "Content Id",
        field: (row) => row.name,
        sortable: true,
        visible: true,
        input: "Input",
        settings: {
          number: true,
          id: true,
        },
      },
      {
        name: "orderportal_project",
        label: "Orderportal Projects",
        field: (row) => row.name,
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      {
        name: "smtp_settings",
        label: "SMTP Settings",
        field: (row) => row.name,
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      {
        name: "landing_page_domain",
        label: "Landing Page Domain",
        field: (row) => row.name,
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      {
        name: "branding",
        label: "Branding",
        field: (row) => row.name,
        sortable: true,
        input: "Branding",
        settings: {},
      },
      {
        name: "logo_url",
        label: "Logo URL",
        field: (row) => row.name,
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      {
        name: "privacy_url",
        label: "Privacy URL",
        field: (row) => row.name,
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      {
        name: "opening_hours",
        label: "Opening Hours",
        field: (row) => row.name,
        input: "Input",
        settings: {
          number: true,
          id: false,
        },
      },
      {
        name: "optin",
        label: "Optin",
        field: (row) => row.name,
        input: "Toggle",
      },
      {
        name: "sms_settings",
        label: "SMS Settings",
        field: (row) => row.name,
        input: "Input",
        settings: {
          number: false,
          id: false,
        },
      },
      {
        name: "component_settings",
        label: "Component Settings",
        field: (row) => row.name,
        input: "ComponentSettings",
        settings: {},
      },
      {
        name: "created_at",
        label: "Created at",
        field: (row) => row.name,
        input: "Date",
        settings: {
          dateTime: true,
        },
      },
      {
        name: "updated_at",
        label: "Updated at",
        field: (row) => row.name,
        input: "Date",
        settings: {
          dateTime: true,
        },
      },
      {
        name: "order_only",
        label: "Order only",
        field: (row) => row.name,
        input: "Toggle",
      },
      {
        name: "save_record_id",
        label:
          "Save Orderportal record id in the multioffer record project specific",
        field: (row) => row.name,
        input: "Toggle",
      },
    ],
  },
};

const get_column_definitions = ({ key }) => {
  if (definitions[key]?.columns) {
    const column_definitions = {
      columns: [],
      visible: [],
    };

    definitions[key]?.columns.forEach((element) => {
      column_definitions.columns.push(element);
      if (element?.visible === true) {
        column_definitions.visible.push(element.name);
      }
    });

    return column_definitions;
  }
};

const get_api_definitions = ({ key }) => {
  if (definitions[key]?.api) {
    return definitions[key].api;
  }
};

const set_api_definitions = ({ key, module }) => {
  if (definitions[key]?.api) {
    store.dispatch(`${module}/setApiDefinitions`, definitions[key]?.api);
  }
};

const get_rows = ({ module }) => {
  store.dispatch(`${module}/getRows`);
};

const get_component_map = ({ columns, module }) => {
  return new Promise((resolve) => {
    const currentRow = store.getters[`${module}/currentRow`];

    const components_map = {
      components: {},
      definitions: {},
    };

    // eslint-disable-next-line no-unused-vars
    for (const [key, column] of Object.entries(columns)) {
      if (typeof components_map.components[column.input] === "undefined") {
        components_map.components[column.input] = defineAsyncComponent(() =>
          import("../components/DataTable/Inputs/" + column.input + ".vue")
        );
      }
    }

    for (const [key] of Object.entries(currentRow)) {
      const column = columns.find((element) => {
        return element.name == key;
      });

      if (column) {
        components_map.definitions[key] = column;
      }
    }

    resolve(components_map);
  });
};

export {
  get_column_definitions,
  get_api_definitions,
  set_api_definitions,
  get_rows,
  get_component_map,
};
