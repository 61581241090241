<template>
  <div class="q-pa-md" style="height: 100vh">
    <q-table
      class="data-table"
      :rows="rows"
      :columns="column_definitions.columns"
      row-key="name"
      virtual-scroll
      :virtual-scroll-item-size="8"
      style="height: 100%"
      separator="cell"
      :visible-columns="column_definitions.visible"
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
            class="text-white bg-primary"
          >
            {{ col.label }}
          </q-th>
          <q-th
            style="width: 2.5vw"
            align="right"
            class="text-white bg-primary"
            key="actions"
          >
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-for="(value, key) in props.row" :key="key" :props="props">
            {{ value }}
          </q-td>
          <q-td align="right" key="actions" style="padding: 5px">
            <q-btn
              flat
              dense
              icon="edit"
              @click="
                confirm = false;
                setCurrentRow(props.row);
                edit_handler();
              "
            />
            <q-btn
              flat
              dense
              icon="delete"
              color="negative"
              @click="
                dialog = true;
                edit = false;
                confirm = true;
                setCurrentRow(props.row);
              "
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="dialog" full-width @hide="clearCurrentRow()">
    <Edit v-if="edit" :module="module" />
    <Delete v-if="confirm" :module="module" />
  </q-dialog>
</template>

<script setup>
import { ref, defineAsyncComponent } from "vue";
import {
  get_column_definitions,
  set_api_definitions,
  get_rows,
  get_component_map,
} from "../plugins/data-table";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
const Edit = defineAsyncComponent(() =>
  import("@/components/DataTable/Edit.vue")
);
import Delete from "../components/DataTable/Delete.vue";

// eslint-disable-next-line no-unused-vars, no-undef
const props = defineProps({
  column_key: String,
});

const $store = useStore();

const module = "datatable";
const dialog = ref(false);
const confirm = ref(false);
const edit = ref(false);

set_api_definitions({ key: props.column_key, module });
get_rows({ module });

const column_definitions = get_column_definitions({ key: props.column_key });
const rows = computed(() => $store.getters[`${module}/rows`]);

const setCurrentRow = (currentRow) =>
  $store.dispatch(`${module}/setCurrentRow`, currentRow);
const clearCurrentRow = () => $store.dispatch(`${module}/setCurrentRow`, {});

const edit_handler = () => {
  get_component_map({
    columns: column_definitions.columns,
    module,
  }).then((response) => {
    $store.dispatch(`${module}/setComponentMapping`, response);
    edit.value = true;
    dialog.value = true;
  });
};
</script>
<style lang="sass">
@import '../styles/quasar.sass'
.data-table
  height: 100%

  .q-table__bottom
    background-color: $grey-3

  thead tr th
    position: sticky
    z-index: 1

  thead tr:first-child th
    top: 0
</style>
