<template>
  <q-card>
    <q-card-section class="row items-center q-py-xs bg-primary text-white">
      <div class="text-h5">You are about to delete a Record, are you sure?</div>
      <q-space />
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-card-actions align="right">
      <q-btn label="Cancel" color="primary" v-close-popup />
      <q-btn
        flat
        label="Delete"
        color="negative"
        v-close-popup
        @click="deleteRow()"
      />
    </q-card-actions>
  </q-card>
</template>

<script setup>
import { defineProps } from "vue";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";

const props = defineProps({
  module: String,
});

const $store = useStore();

const currentRow = computed(() => $store.getters[`${props.module}/currentRow`]);

function deleteRow() {
  $store.dispatch(`${props.module}/deleteRow`, currentRow.value["id"]);
}
</script>

<style></style>
